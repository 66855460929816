import React, { useCallback, useEffect, useState } from "react";
import { Auth } from "aws-amplify";
import { AuthenticationContextProvider } from "./AuthenticationContext";
import { UserContextProvider } from "./UserContext";
/**
 * This is written specifically for Amplify/Hub, however, you can replace
 * this provider with another provider (say <AzureProvider />) that replicates
 * the API above and everything using useAuth and useUser should just work!
 */
export const AuthProvider = ({ children }) => {
    // on mount, store the user and listen to hub changes (Amplify's internal)
    const [user, setUser] = useState(undefined);
    useEffect(() => {
        async function getUserFromStorage() {
            try {
                const user = await Auth.currentAuthenticatedUser();
                setUser(user === "not authenticated" ? undefined : user);
            }
            catch (error) {
                console.log("No user authenticated.");
            }
        }
        getUserFromStorage();
    }, []);
    const handleSignOut = useCallback(async ({ global }) => {
        try {
            await Auth.signOut({ global });
            setUser(undefined);
        }
        catch (e) {
            // handle global sign out
            if (e.code === "NotAuthorizedException") {
                setUser(undefined);
                return;
            }
            throw e;
        }
    }, []);
    const handleSignIn = useCallback(async (email, password) => {
        try {
            const user = await Auth.signIn(email, password);
            setUser(user);
        }
        catch (e) {
            console.warn("Error with signing in.");
            throw e;
        }
    }, []);
    const handleCompleteNewPassword = useCallback(async (password) => {
        try {
            const updatedUser = await Auth.completeNewPassword(user, password, {});
            setUser(updatedUser);
        }
        catch (e) {
            console.warn("Error with completing new password.");
            throw e;
        }
    }, [user]);
    return (React.createElement(AuthenticationContextProvider, { value: {
            signIn: handleSignIn,
            signOut: handleSignOut,
            forgotPassword: Auth.forgotPassword,
            changePassword: Auth.changePassword,
            completeNewPassword: handleCompleteNewPassword,
        } },
        React.createElement(UserContextProvider, { value: user }, children)));
};

import React from "react";
import { List, ListItem, ListItemText, ListItemIcon, Avatar, } from "@material-ui/core";
/**
 * An easy way to display a list of information, such as search results
 * in list form. See `ResultsCardList` for a grid-list results component.
 */
export const ResultsList = ({ results, onSelect, right = null, ...props }) => results ? (React.createElement(List, { ...props }, results.map((result, index) => {
    const { primary, secondary, icon: Icon, id } = result;
    return (React.createElement(ListItem, { button: true, onClick: () => onSelect(result), key: id || index },
        Icon && (React.createElement(ListItemIcon, null,
            React.createElement(Avatar, null,
                React.createElement(Icon, { style: { color: "#fff" }, "data-testid": `result-icon-${index}` })))),
        React.createElement(ListItemText, { primary: primary, secondary: secondary }),
        typeof right === "function" ? right(result) : right));
}))) : null;
/** Alias for search results */
export const SearchResults = ResultsList;

import { useContext } from "react";
import { AuthenticationContext } from "../AuthProvider";
/**
 * Exposes method to control authentication.
 *
 * Use this over the default aws-amplify Auth instance as this cleans
 * up the user object, and local storage.
 */
export function useAuth() {
    const context = useContext(AuthenticationContext);
    if (!context)
        throw Error("No AuthenticationContext. Did you forget to wrap your app in <AuthenticationProvider />?");
    return context;
}

import { useContext } from "react";
import { AppContext } from "../AppContext";
export const NOT_INSIDE_APP_CONTEXT_PROVIDER_ERROR = "Did you forget to wrap your app in <AppContextProvider />?";
/**
 * TODO: not needed once experience apps are separated
 * Context consumer hook to be used throughout platform apps.
 */
export function useApp() {
    const context = useContext(AppContext);
    if (!context)
        throw Error(NOT_INSIDE_APP_CONTEXT_PROVIDER_ERROR);
    return context;
}

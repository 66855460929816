import React from "react";
import { Box, Grid } from "@material-ui/core";
/**
 * A handy component for consistent and thoughtfully spaced layouts.
 * Extends [`@material-ui/core/Grid`](https://material-ui.com/components/grid/).
 */
export const InsetWrap = ({ children, ...props }) => {
    return (React.createElement(Grid, { container: true, spacing: 3 },
        React.createElement(Grid, { item: true, md: 2 }, "\u00A0"),
        React.createElement(Grid, { item: true, md: 9, ...props },
            React.createElement(Box, { pt: 4 }, children))));
};
export default InsetWrap;

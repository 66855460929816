import React from "react";
import { Box } from "@material-ui/core";
// import { BoxProps } from '@material-ui/core/Box';
import chunk from "lodash.chunk";
import { Tile } from "../";
import { Skrim } from "../";
/**
 * A scrollable, horizontal grid of `<Tile />`s.
 */
export const HorizontalTileGrid = ({ rows: numRows = 2, content, activeTileId, onSelect, tileProps = {}, }) => {
    // split our content object into rows
    const rows = chunk(content, Math.ceil(content.length / numRows));
    const handleTileClick = (data) => {
        if (onSelect !== undefined) {
            onSelect(data);
        }
    };
    return (React.createElement(Box, { position: "relative" },
        React.createElement(Skrim, { angle: 90, width: 16, height: 1, left: 0 }),
        React.createElement(Skrim, { angle: -90, width: 32, height: 1, right: 0 }),
        React.createElement(Box, { width: 1, position: "relative", style: {
                WebkitOverflowScrolling: "touch",
                overflowX: "scroll",
                overflowY: "hidden",
            } },
            React.createElement(Box, { whiteSpace: "nowrap", p: 1 }, rows.map((row, index) => (React.createElement(Box, { pl: 2, display: "flex", key: index, "data-testid": `row-${index}` }, row.map((item) => {
                const { title, id } = item;
                return (React.createElement(Tile, { onClick: () => handleTileClick(item), active: id === activeTileId || item.slug === activeTileId, key: id, width: 232, mr: 2, mb: 2, whiteSpace: "wrap", title: title, ...item, ...tileProps }));
            }))))))));
};

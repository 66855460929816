import React from "react"
import { SnackbarProvider, Theme } from "@citydna/common"
import { AuthProvider } from "@citydna/platform"
import { BrowserRouter } from "react-router-dom"
import { GraphQLProvider } from "./GraphQLProvider"

export const Providers: React.FC = ({ children }) => {
  return (
    <Theme>
      <SnackbarProvider>
        <BrowserRouter>
          <AuthProvider>
            <GraphQLProvider>{children}</GraphQLProvider>
          </AuthProvider>
        </BrowserRouter>
      </SnackbarProvider>
    </Theme>
  )
}

import React, { useMemo } from "react"
import { Auth } from "aws-amplify"
import { createAuthLink } from "aws-appsync-auth-link"
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client"
import { useUser } from "@citydna/platform"
import config from "../aws-exports"

const httpLink = createHttpLink({
  uri: config.aws_appsync_graphqlEndpoint,
})

const createClient = () => {
  const awsLink = createAuthLink({
    url: config.aws_appsync_graphqlEndpoint,
    region: config.aws_appsync_region,
    auth: {
      type: config.aws_appsync_authenticationType as "AMAZON_COGNITO_USER_POOLS",
      jwtToken: async () =>
        (await Auth.currentSession()).getAccessToken().getJwtToken(),
    },
  })

  return new ApolloClient({
    link: awsLink.concat(httpLink),
    cache: new InMemoryCache(),
  })
}

export const GraphQLProvider: React.FC = ({ children }) => {
  const user = useUser()

  const client = useMemo(() => {
    return createClient()
    // eslint-disable-next-line
  }, [user])

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

import React, { useMemo } from "react";
import { Typography, Link } from "@material-ui/core";
import ReactMarkdown from "react-markdown";
/**
 * A component that converts an raw Markdown string into @material-ui/core components.
 */
export const ReactMarkdownMui = ({ children, variant = "body2" }) => {
    const components = useMemo(() => ({
        h2: ({ children }) => React.createElement(Typography, { variant: "h2" }, children),
        p: ({ children }) => (React.createElement(Typography, { variant: variant, paragraph: true }, children)),
        a: ({ children, href }) => {
            if (href.startsWith("http://") || href.startsWith("https://")) {
                return (React.createElement(Link, { href: href, target: "_blank", rel: "noopener noreferrer" }, children));
            }
            return React.createElement(Link, { href: href }, children);
        },
    }), [variant]);
    return React.createElement(ReactMarkdown, { components: components }, children);
};

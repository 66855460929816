import React from "react";
import { Box, CircularProgress, Zoom, useTheme } from "@material-ui/core";
import Logo from "../Logo";
import MicroLogo from "../MicroLogo";
/**
 * A universal header with branding and slots to add components to the left (after the branding) and right (far right of screen).
 * Extends [`@material-ui/core/Box`](https://material-ui.com/components/box/).
 */
export const Header = ({ left, right, microBranding = false, loading = false, ...rest }) => {
    const theme = useTheme();
    return (React.createElement(Box, { p: 2, pl: 3, alignItems: "center", display: "flex", position: "fixed", left: 0, top: 0, right: 0, zIndex: 9, bgcolor: theme.palette.type === "dark" ? "grey.800" : "white", "data-testid": "header-wrapper", ...rest },
        React.createElement(Box, { position: "relative", width: microBranding ? 32 : 144, height: microBranding ? 32 : 64, mr: 3, mt: -0.4 },
            React.createElement(Zoom, { in: !loading, appear: false },
                React.createElement(Box, { position: "absolute" }, microBranding ? React.createElement(MicroLogo, null) : React.createElement(Logo, { width: 144, height: 64 }))),
            React.createElement(Zoom, { in: loading },
                React.createElement(Box, { position: "absolute" },
                    React.createElement(CircularProgress, { size: microBranding ? 32 : 56, style: {
                            color: theme.palette.type === "dark"
                                ? theme.palette.common.white
                                : theme.palette.grey[800],
                        }, "data-testid": "header-loading" })))),
        left && React.createElement(Box, null, left),
        right && React.createElement(Box, { marginLeft: "auto" }, right)));
};
export default Header;

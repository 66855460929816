import { useContext } from "react";
import { UserContext } from "../AuthProvider";
/**
 *
 * Get information on currently logged in user.
 *
 * Will return undefined when user is not logged in.
 *
 */
export function useUser() {
    const context = useContext(UserContext);
    return context;
}

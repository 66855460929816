import React from "react";
import { Box, TextField, IconButton } from "@material-ui/core";
import { Search as SearchIcon } from "@material-ui/icons";
import { Close as CloseIcon } from "@material-ui/icons";
/**
 * A form input that resembles search, including internal state for
 * clearing the form. Use in conjunction with ResultsList for great looking search forms.
 * Extends [`@material-ui/core/FilledInput`](https://material-ui.com/api/filled-input/).
 * Works great with `setState()`.
 */
export const SearchForm = ({ onClear, label = "Search for content", ...props }) => {
    const { value } = props;
    return (React.createElement(Box, { mb: 4 },
        React.createElement(TextField, { variant: "filled", type: "text", label: label, fullWidth: true, autoComplete: "off", id: "com-searchform", ...props, InputProps: {
                disableUnderline: true,
                endAdornment: value && value.length && onClear !== undefined ? (React.createElement(IconButton, { onClick: () => onClear(), title: "Clear search form" },
                    React.createElement(CloseIcon, { style: { fontSize: 48 } }))) : (React.createElement(SearchIcon, { style: { fontSize: 48 } })),
            } })));
};
export default SearchForm;

import React from "react"
import ReactDOM from "react-dom"
import * as serviceWorker from "./serviceWorker"

import { Providers } from "./components/Providers"
import { Router } from "./components/Router"

import Amplify from "aws-amplify"
import config from "./aws-exports"
Amplify.configure(config)

ReactDOM.render(
  <Providers>
    <Router />
  </Providers>,
  document.getElementById("root")
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()

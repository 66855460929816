import React from "react"
import { Box, CircularProgress } from "@material-ui/core"

export const FullscreenLoader = () => {
  return (
    <Box
      width="100vw"
      height="100vh"
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      <CircularProgress data-testid="suspense-loading" color="secondary" />
    </Box>
  )
}

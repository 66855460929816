import React, { useEffect } from "react"
import { useUser } from "@citydna/platform"
import { useHistory } from "react-router-dom"
import { useSnackbar } from "notistack"
import { FullscreenLoader } from "./FullscreenLoader"
import { CognitoUser } from "@aws-amplify/auth"

const UnauthenticatedRoutes = React.lazy(
  () => import("./UnauthenticatedRoutes")
)
const AuthenticatedRoutes = React.lazy(() => import("./AuthenticatedRoutes"))

/** package doesn't include challengeName */
interface CognitoUserChallengeName extends CognitoUser {
  challengeName: string
}

export const Router = () => {
  const user = useUser() as CognitoUserChallengeName
  const history = useHistory()
  const { enqueueSnackbar } = useSnackbar()

  useEffect(() => {
    if (user && user.challengeName === "NEW_PASSWORD_REQUIRED") {
      enqueueSnackbar("Your first time logging in! Please set a new password.")
      history.push("/auth/set-password")
      return
    }
    if (user) {
      enqueueSnackbar("Signed in successfully", {
        variant: "success",
      })
    }
  }, [user, enqueueSnackbar, history])

  return (
    <React.Suspense fallback={<FullscreenLoader />}>
      {!user || user.challengeName === "NEW_PASSWORD_REQUIRED" ? (
        <UnauthenticatedRoutes />
      ) : (
        <AuthenticatedRoutes />
      )}
    </React.Suspense>
  )
}
